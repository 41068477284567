import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import { Categories, NewArticle, BlogTails } from "page_components/blog"

let filtered_posts = []
let posts_to_show = []

const Blog = ({ data }) => {
  const [currentCategory, setCurrentCategory] = useState(null)
  const [showAllPosts, setShowAllPosts] = useState(false)

  const breadcrumbs_data = [
    {
      name: "Blog",
      href: "/blog/",
    },
  ]

  const categories_list = data?.allWpCategory?.nodes
  const first_article = data?.allWpPost?.nodes[0]
  const articles_list = data?.allWpPost?.nodes.slice(1)

  if (currentCategory === null) {
    filtered_posts = articles_list
  } else {
    filtered_posts = articles_list?.filter(article =>
      article.categories.nodes.some(e => e.name === currentCategory)
    )
  }

  if (showAllPosts) {
    posts_to_show = filtered_posts
  } else {
    if (filtered_posts.length > 8) {
      posts_to_show = filtered_posts.slice(0, 8)
    } else {
      posts_to_show = filtered_posts
    }
  }

  return (
    <Layout
      seo={{
        title: "Blog",
        description: "",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Blog" />
      <NewArticle new_article={first_article} />
      <Categories
        categories_list={categories_list}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
      />
      <BlogTails
        posts_to_show={posts_to_show}
        showAllPosts={showAllPosts}
        setShowAllPosts={setShowAllPosts}
        show_button={filtered_posts?.length}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpCategory(filter: { name: { ne: "Uncategorized" } }) {
      nodes {
        name
      }
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        acfPosts {
          image {
            altText
            sourceUrl
          }
        }
        excerpt
        categories {
          nodes {
            name
          }
        }
        id
      }
    }
  }
`

export default Blog
